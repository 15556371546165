import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getLabelfromValue } from "../../util/Utility";
import { DIALOG_BOX_EDIT } from "../../util/Constants";

export const TruckingCompanyPerUserCompoundAccess = (props) => {

    const { t } = useTranslation();
    
    const setSelectedTruckingCompaniesForEditDialogueBox = (selectedCompound) => {
        let temp = props.compoundTruckingCompaniesMapping.filter((obj) => obj.compoundCode === selectedCompound);
        return temp.length > 0 ? temp[0].truckingCompanyList : [];
    }
    
    const [selectedTruckingCompanies, setSelectedTruckingCompanies] = useState(props.dialogBoxFor && props.dialogBoxFor === DIALOG_BOX_EDIT ?
        setSelectedTruckingCompaniesForEditDialogueBox(props.selectedCompound) : []);

    const onChangeTruckingCompany = (e) => {
        setSelectedTruckingCompanies(e.value)
        props.handleTruckingCompanyMappingChangeForCompound(props.selectedCompound, e.value);
    }

    return (
        <>
            <div className="">

                <div className="flex justify-content-center mb-1">
                    <label
                        htmlFor="CompoundName"
                        className="mr-2 font-bold" >
                        {"For Compound - " + getLabelfromValue(props.selectedCompound, props.compoundList)}
                    </label>
                </div>

                <div className="flex align-self-end">
                    <span htmlFor="truckingCompanies"
                        className="mt-2 mr-2"
                    >
                        {t("label.truckingCompanies")}
                        <b className="text-primary"> *</b>
                    </span>
                    <span className="p-float-label w-15rem">
                        <MultiSelect
                            value={selectedTruckingCompanies}
                            options={props.truckingCompanyList}
                            onChange={(e) => {
                                onChangeTruckingCompany(e);
                                props.setErrorCompoundTruckingCompaniesMapping(
                                    {
                                        error: false,
                                        message: "",
                                    }
                                )
                            }}
                            maxSelectedLabels={3}
                            className={"w-full"}
                            showClear
                            display="chip"
                            filter
                        // placeholder="Select Trucking Companies"
                        />
                    </span>
                </div>

            </div>

        </>
    );
}