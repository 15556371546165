import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getLabelfromValue, getPaginationRows } from "../../util/Utility";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { AddOrEditUserDialog } from "./AddOrEditUserDialog";
import { useTranslation } from "react-i18next";
import { DIALOG_BOX_EDIT, DIALOG_BOX_FOR_ADD } from "../../util/Constants";
import { API_GET_ALL_USERS, API_GET_TRUCKING_COMPANY } from "../../actions/ApiConstants";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { getCallWithoutReducer } from "../../actions/performAction";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

export const UserManager = () => {
  useEffect(() => {
    dispatch(
      getCallWithoutReducer(API_GET_ALL_USERS, "", setUserList, onFailure)
    );
    dispatch(
      getCallWithoutReducer(
        API_GET_TRUCKING_COMPANY,
        "",
        setTruckingCompanyList,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [addOrEditDialogVisible, setAddOrEditDialogVisible] = useState(false);
  const [dialogBoxFor, setDialogBoxFor] = useState("");
  const [rowToEditData, setRowToEditData] = useState();
  const [userList, setUserList] = useState();
  const [truckingCompanyList, setTruckingCompanyList] = useState([]);

  const onSuccessSaveAddOrEditUser = () => {
    toast.current.show({
      severity: "success",
      summary: "User saved",
      detail: "User saved succefully.",
    });

    dispatch(
      getCallWithoutReducer(API_GET_ALL_USERS, "", setUserList, onFailure)
    );
    setAddOrEditDialogVisible(false);
  };

  const onFailure = (error) => {
    toast.current.show({ severity: "error", summary: "Error", detail: error });
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const setResultTableHeader = () => {
    return (
      <div>
        <div className="inline">{t("label.compounds")}</div>

        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const actionOnUserDetails = (rowData) => {
    return (
      <div className=" flex justify-content-center text-primary">
        <div className="">
          <i
            className="pi pi-pencil font-semibold cursor-pointer"
            title="Edit"
            onClick={(e) => onClickEdit(e, rowData)}
          ></i>
        </div>
      </div>
    );
  };

  const onClickAddButton = () => {
    setRowToEditData("");
    setDialogBoxFor(DIALOG_BOX_FOR_ADD);
    setAddOrEditDialogVisible(true);
  };

  const onClickEdit = (e, rowData) => {
    setDialogBoxFor(DIALOG_BOX_EDIT);
    setRowToEditData(rowData);
    setAddOrEditDialogVisible(true);
  };

  // const setAccessField = (rowData) => {
  //     return (
  //         rowData.userCompoundAccessDtos &&
  //         rowData.userCompoundAccessDtos.map((dto) => (
  //             <div key={dto.label}>
  //                 <>
  //                     {dto.label}
  //                     {dto.subCompounds && <> - ( {dto.subCompounds.join(", ")} )</>}
  //                 </>
  //             </div>
  //         ))
  //     );
  // };

  const getSubCompound = (rowData) => {
    return (
      rowData.subCompounds &&
      //   rowData.userCompoundAccessDtos.map((dto) => (
      //       <div key={dto.label}>
      //           <>
      //               {dto.label}
      rowData.subCompounds
        .map((subCompound) => subCompound.subCompoundName)
        .join(", ")
      //           </>
      //       </div>
      //   ))
    );
  };

  const setAccessField = (rowData) => {
    // console.log(mainTableRowData)
    let mainTableRowData = rowData;
    return rowData.userCompoundAccessDtos ? (
      <div id="user-Manager-Table-access">
        <DataTable
          value={rowData.userCompoundAccessDtos}
          scrollable
          stripedRows
          tableStyle={{ minWidth: "10rem" }}
          dataKey="label"
          metaKeySelection={false}
          dragSelection
          showGridlines
        >
          <Column field="label" header={t("label.mainCompound")}></Column>
          <Column
            field="userCompoundTruckingCompaniesMappingDtos"
            body={(rowData) => setCompoundTruckingCompanyMappingField(mainTableRowData, rowData)}
            header={t("label.compoundTruckingCompanyMapping")}
            sortable
          />
          <Column
            field="label"
            header={t("label.subCompound")}
            body={getSubCompound}
          ></Column>
        </DataTable>
      </div>
    ) : (
      <></>
    );

    // rowData.userCompoundAccessDtos &&
    // rowData.userCompoundAccessDtos.map((dto) => (
    //     <div key={dto.label}>
    //         <>
    //             {dto.label}
    //             {dto.subCompounds && <> - ( {dto.subCompounds.join(", ")} )</>}
    //         </>
    //     </div>
    // ))
  };

  const setCompoundTruckingCompanyMappingField = (mainTableRowData, rowData) => {
    return <div>
      {mainTableRowData.userCompoundTruckingCompaniesMappingDtos &&
        mainTableRowData.userCompoundTruckingCompaniesMappingDtos.map((compoundTruckingCompanyMapping) => {
          if (compoundTruckingCompanyMapping.compoundCode === rowData.value) {

            let truckingCompanyNameList = Array.from(compoundTruckingCompanyMapping.truckingCompanyList.map((truckingCompanyCode) => {
               return getLabelfromValue(truckingCompanyCode, truckingCompanyList)}));
            return truckingCompanyNameList.join(", ");
          }
        })
      }
    </div>
  }

  const setStatusField = (rowData) => {
    return rowData.status === true ? <div>Active</div> : <div>In-Active</div>;
  };

  return (
    <>
      {addOrEditDialogVisible && (
        <AddOrEditUserDialog
          addOrEditDialogVisible={addOrEditDialogVisible}
          setAddOrEditDialogVisible={setAddOrEditDialogVisible}
          dialogBoxFor={dialogBoxFor}
          rowData={rowToEditData}
          onSuccessSaveAddOrEditUser={onSuccessSaveAddOrEditUser}
          truckingCompanyList={truckingCompanyList}
        />
      )}

      <Toast ref={toast} position="top-center" />

      <div className="flex justify-content-end">
        <Button
          label={t("button.add")}
          icon="pi pi-user-plus"
          onClick={onClickAddButton}
          severity="primary"
        />
      </div>
      <br />
      <div id="user-Manager-Table">
        <DataTable
          value={userList}
          scrollable
          scrollHeight="400px"
          header={setResultTableHeader}
          filters={filters}
          globalFilterFields={["userId", "userName"]}
          stripedRows
          tableStyle={{ minWidth: "50rem" }}
          dataKey="id"
          metaKeySelection={false}
          paginator
          rows={20}
          rowsPerPageOptions={getPaginationRows()}
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          dragSelection
          removableSort
          showGridlines
        >
          <Column field="userId" header={t("label.userId")} sortable></Column>
          <Column field="userName" header={t("label.userName")} sortable />
          <Column
            field="access"
            header={t("label.compoundAccess")}
            body={setAccessField}
            sortable
          />
          <Column
            field="status"
            body={setStatusField}
            header={t("label.status")}
            sortable
          />
          <Column
            header={t("label.action")}
            align={"center"}
            body={actionOnUserDetails}
          />
        </DataTable>
      </div>
    </>
  );
};
