// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { Checkbox } from 'primereact/checkbox';
// import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
// import { useTranslation } from "react-i18next";

// export const ArrivalLcpSelectionTable = forwardRef((prop, ref) => {

//     useImperativeHandle(ref, () => {
//         return {
//             combineSelections,
//         }
//     })

//     const [selectedSubCompounds, setSelectedSubCompounds] = useState()

//     useEffect(() => {
//         setSelectedSubCompounds(prop.selectedSubCompounds)
//         setarrivalTrueSubCompounds(prop.selectedSubCompounds ? Array.from(prop.selectedSubCompounds.map((subCompound) => subCompound.arrival ? subCompound.subCompoundCode : "")) : [])
//         setLcpTrueSubCompounds(prop.selectedSubCompounds ? Array.from(prop.selectedSubCompounds.map((subCompound) => subCompound.loadComplete ? subCompound.subCompoundCode : "")) : [])

//     }, [prop.selectedSubCompounds])

//     const { t } = useTranslation();
//     const [arrivalTrueSubCompounds, setarrivalTrueSubCompounds] = useState([])
//     const [arrivalFalseSubCompounds, setArrivalFalseSubCompounds] = useState([])
//     const [lcpTrueSubCompounds, setLcpTrueSubCompounds] = useState([])
//     const [lcpFalseSubCompounds, setLcpFalseSubCompounds] = useState([])

//     const handleCheckBoxForArrival = (event) => {
//         const subCompoundCode = event.target.value;
//         if (event.target.checked) {
//             setarrivalTrueSubCompounds([...arrivalTrueSubCompounds, subCompoundCode])
//             setArrivalFalseSubCompounds(arrivalFalseSubCompounds.filter(subCompound => subCompound !== subCompoundCode))


//         } else {
//             setArrivalFalseSubCompounds([...arrivalFalseSubCompounds, subCompoundCode])
//             setarrivalTrueSubCompounds(arrivalTrueSubCompounds.filter(subCompound => subCompound !== subCompoundCode))
//         }
//     }
//     const setCheckBoxForArrivalCol = (rowData) => {
//         return <div className="flex align-items-center">
//             <Checkbox
//                 value={rowData.subCompoundCode}
//                 onChange={(event) => { handleCheckBoxForArrival(event); }}
//                 checked={arrivalTrueSubCompounds.includes(rowData.subCompoundCode)}
//             />
//         </div>
//     }

//     const handleCheckBoxForLcp = (event) => {
//         const subCompoundCode = event.target.value;

//         if (event.target.checked) {
//             setLcpTrueSubCompounds([...lcpTrueSubCompounds, subCompoundCode])
//             setLcpFalseSubCompounds(lcpFalseSubCompounds.filter(subCompound => subCompound !== subCompoundCode))

//         } else {
//             setLcpFalseSubCompounds([...lcpFalseSubCompounds, subCompoundCode])
//             setLcpTrueSubCompounds(lcpTrueSubCompounds.filter(subCompound => subCompound !== subCompoundCode))
//         }
//     }

//     const setCheckBoxForLCPCol = (rowData) => {
//         return <div className="flex align-items-center">
//             <Checkbox
//                 value={rowData.subCompoundCode}
//                 onChange={(event) => { handleCheckBoxForLcp(event) }}
//                 checked={lcpTrueSubCompounds.includes(rowData.subCompoundCode)}
//             />
//         </div>
//     }

//     const combineSelections = () => {
//         let arr = [];
//         if (selectedSubCompounds.length > 0) {
//             arr = selectedSubCompounds
//             arr.map((subCompoundDto) => {
//                 arrivalTrueSubCompounds.forEach((arrivalTrueSelection) => arrivalTrueSelection === subCompoundDto.subCompoundCode ? subCompoundDto.arrival = true : "");
//                 lcpTrueSubCompounds.forEach((lcpTrueSelection) => lcpTrueSelection === subCompoundDto.subCompoundCode ? subCompoundDto.loadComplete = true : "")

//                 arrivalFalseSubCompounds.forEach((arrivalFalseSelection) => arrivalFalseSelection === subCompoundDto.subCompoundCode ? subCompoundDto.arrival = false : "");
//                 lcpFalseSubCompounds.forEach((lcpFalseSelection) => lcpFalseSelection === subCompoundDto.subCompoundCode ? subCompoundDto.loadComplete = false : "")
//             })
//         }
//         return arr;
//     }

//     return (
//         <>
//             <div>
//                 <DataTable value={prop.selectedSubCompounds}
//                     dataKey={"subCompoundCode"}
//                     stripedRows
//                     showGridlines
//                     header={"Select Arrival/Load Complete For Sub-Compound"}
//                 >
//                     <Column field="subCompoundName" header={t("label.subCompound")} ></Column>
//                     <Column field="arrival" body={setCheckBoxForArrivalCol} header={t("label.arrival")} ></Column>
//                     <Column field="loadComplete" body={setCheckBoxForLCPCol} header={t("label.loadComplete")}></Column>
//                 </DataTable>
//             </div>
//         </>
//     )
// })