import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from 'react';
import { Chart as PrimeChart } from 'primereact/chart';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Fieldset } from "primereact/fieldset";
import { getCallWithoutBlock, getCallWithoutReducer } from "../../actions/performAction";
import { API_GET_COUNT_OF_ARRIVAL_AND_LCP_BY_DATE, API_GET_COUNT_OF_FINAL_ARRIVAL_BY_DATE, API_GET_SUB_COMPOUNDS_OF_MAIN_COMPOUND } from "../../actions/ApiConstants";
import { Toast } from "primereact/toast";
import { BANNER_LIFE_TIME, CALENDAR_FORMAT } from "../../util/Constants";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import './Dashboard.css';
import { convertToDashYYYYMMDD } from "../../util/Utility";

Chart.register(annotationPlugin);

export const Dashboard = () => {
  const { t } = useTranslation();
  const compound = useSelector((state) => state.compoundReducer);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const navigate = useNavigate();

  const [arrivalAndLcpChartLine, setArrivalAndLcpChartLine] = useState({});
  const [arrivalAndLcpChartOptionsLine, setArrivalAndLcpChartOptionsLine] = useState({});
  const [finalArrivalChartLine, setFinalArrivalChartLine] = useState({});
  const [finalArrivalChartOptionsLine, setFinalArrivalChartOptionsLine] = useState({});
  const [selectedSubCompound, setSelectedSubCompound] = useState(null);
  const [subCompoundList, setSubCompoundList] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  //Add 10 to fromDate to get toDate
  const [toDate, setToDate] = useState(new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000));

  useEffect(() => {

    resetGraphData();
    let obj = {
      fromDate: convertToDashYYYYMMDD(fromDate),
      toDate: convertToDashYYYYMMDD(toDate),
    }

    dispatch(getCallWithoutBlock(API_GET_COUNT_OF_ARRIVAL_AND_LCP_BY_DATE, obj, "", makeGraphForArrivalAndLcp, onFailure));

    dispatch(
      getCallWithoutReducer(
        API_GET_SUB_COMPOUNDS_OF_MAIN_COMPOUND,
        "",
        setDataToSubCompoundList,
        onFailure
      )
    );
  }, [compound]);

  const resetFinalArrivalGraphData = () => {
    setFinalArrivalChartLine({});
    setFinalArrivalChartOptionsLine({});
  }
  const resetGraphData = () => {
    //Resetting the graph data
    setArrivalAndLcpChartLine({});
    setArrivalAndLcpChartOptionsLine({});
    resetFinalArrivalGraphData();
  }

  const setDataToSubCompoundList = (data) => {

    setSubCompoundList(data);

    //Automatically select that sub compound in case there is only one
    if (data && data.length === 1) {
      setSelectedSubCompound(data[0].value)

      let obj = {
        fromDate: convertToDashYYYYMMDD(fromDate),
        toDate: convertToDashYYYYMMDD(toDate),
        subCompound: data[0].value
      }

      dispatch(
        getCallWithoutBlock(
          API_GET_COUNT_OF_FINAL_ARRIVAL_BY_DATE,
          obj,
          "",
          makeGraphForFinalArrival,
          onFailure
        )
      );
    }
  }

  const makeGraphForArrivalAndLcp = (data) => {

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arrivedData = data.arrived;
    let inBoundData = data.inBound;
    let lcpData = data.lcp;
    let inStockData = data.inStock;

    const graphData = {
      // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      labels: Array.from(arrivedData.map((item) => { return item.date })),
      datasets: [
        {
          label: t("label.arrived"),
          backgroundColor: documentStyle.getPropertyValue('--green-400'),
          borderColor: documentStyle.getPropertyValue('--green-400'),
          // data: [65, 59, 80, 81, 56, 55, 40]
          data: Array.from(arrivedData.map((item) => { return item.count })),
        },
        {
          label: t("label.inBound"),
          backgroundColor: documentStyle.getPropertyValue('--red-400'),
          borderColor: documentStyle.getPropertyValue('--red-400'),
          data: Array.from(inBoundData.map((item) => { return item.count })),
        },
        {
          label: t("label.inStock"),
          backgroundColor: documentStyle.getPropertyValue('--blue-400'),
          borderColor: documentStyle.getPropertyValue('--blue-400'),
          data: Array.from(inStockData.map((item) => { return item.count })),
        },
        {
          label: t("label.loadComplete"),
          backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
          borderColor: documentStyle.getPropertyValue('--yellow-400'),
          // data: [78, 35, 38, 55, 61, 27, 60]
          data: Array.from(lcpData.map((item) => { return item.count })),
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

    setArrivalAndLcpChartLine(graphData);
    setArrivalAndLcpChartOptionsLine(options);
  }

  const makeGraphForFinalArrival = (data) => {

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arrivedData = data.arrived;
    let inBoundData = data.inBound;

    const graphData = {
      // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      labels: Array.from(arrivedData.map((item) => { return item.date })),
      datasets: [
        {
          label: t("label.arrived"),
          backgroundColor: documentStyle.getPropertyValue('--indigo-300'),
          borderColor: documentStyle.getPropertyValue('--indigo-300'),
          // data: [65, 59, 80, 81, 56, 55, 40]
          data: Array.from(arrivedData.map((item) => { return item.count })),
        },
        {
          label: t("label.inBound"),
          backgroundColor: documentStyle.getPropertyValue('--pink-300'),
          borderColor: documentStyle.getPropertyValue('--pink-300'),
          // data: [25, 56, 72, 80, 89, 44, 10]
          data: Array.from(inBoundData.map((item) => { return item.count })),
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

    setFinalArrivalChartLine(graphData);
    setFinalArrivalChartOptionsLine(options);
  }

  const onFailure = (error) => {
    toast.current.show({ severity: "error", summary: "Error", detail: error, life: BANNER_LIFE_TIME });

    resetGraphData();
  };

  const onSubCompoundDropDownChange = (e) => {
    setSelectedSubCompound(e.value);

    let obj = {
      fromDate: convertToDashYYYYMMDD(fromDate),
      toDate: convertToDashYYYYMMDD(toDate),
      subCompound: e.value
    }
    dispatch(getCallWithoutBlock(API_GET_COUNT_OF_FINAL_ARRIVAL_BY_DATE, obj, "", makeGraphForFinalArrival, onFailure));
    resetFinalArrivalGraphData();
  }

  const onFromDateChange = (e) => {
    setFromDate(e.value);
    // setToDate(new Date(new Date().setDate(e.value.getDate() + 10)));
    setToDate(new Date(e.value.getTime() + 10 * 24 * 60 * 60 * 1000));
  }

  const onClickSearch = () => {
    let obj = {
      fromDate: convertToDashYYYYMMDD(fromDate),
      toDate: convertToDashYYYYMMDD(toDate),
    }
    dispatch(getCallWithoutBlock(API_GET_COUNT_OF_ARRIVAL_AND_LCP_BY_DATE, obj, "", makeGraphForArrivalAndLcp, onFailure));

    if (selectedSubCompound !== null) {
      obj = { ...obj, subCompound: selectedSubCompound }

      dispatch(
        getCallWithoutBlock(
          API_GET_COUNT_OF_FINAL_ARRIVAL_BY_DATE,
          obj,
          "",
          makeGraphForFinalArrival,
          onFailure
        )
      );
    }
    resetGraphData();
  }

  return (
    <>
      <Toast ref={toast} position="top-center" />
      {/* <div className="flex justify-content-center" >
        <label htmlFor="header" className="font-bold mb-2 text-primary">
          <h1>Dashboard</h1>
        </label>
      </div> */}

      <div className="grid m-2 md:mb-5 mt-3 mb-3 row-gap-2">
        <div className="md:col-4 col-12">
          <span className="p-float-label md:w-8 w-full">
            <Calendar
              inputId="fromDate"
              dateFormat={CALENDAR_FORMAT}
              value={fromDate}
              onChange={(e) => {
                onFromDateChange(e);
              }}
              showIcon
            />
            <label htmlFor="startDate">{t("label.fromDate")}</label>
          </span>
        </div>

        <div className="md:col-4 col-12">
          <span className="p-float-label md:w-8 w-full">
            <Calendar
              inputId="toDate"
              dateFormat={CALENDAR_FORMAT}
              value={toDate}
              onChange={(e) => {
                setToDate(e.value);
                // setErrorInToDate({ error: false, message: "" });
              }}
              disabled
              showIcon
            />
            <label htmlFor="toDate">{t("label.toDate")}</label>
          </span>
        </div>

        <div className="md:col-4 col-12">
          <div className=" flex justify-content-end">
            <Button
              label={t("button.search")}
              icon="pi pi-search"
              severity="primary"
              onClick={onClickSearch}
            />
          </div>
        </div>
      </div>

      {/* Graphs */}

      <div className="grid mt-1">
        <div className={`${compound && compound.subCompounds ? "md:col-6 col-12" : "col-12"}`}>
          <Fieldset className="flex justify-content-center align-items-end border-round-md" style={{ boxShadow: "0px 0px 1px #6c757d", height: "92%" }}>

            <PrimeChart type="bar"
              className="responsive-graph"
              data={arrivalAndLcpChartLine}
              options={arrivalAndLcpChartOptionsLine}
            />

          </Fieldset>

          <div className="flex justify-content-center mt-2" >
            <label htmlFor="header" className="mb-4 text-primary">
              <h2 className="inline cursor-pointer underline"
                onClick={() => { navigate("/arrival") }}
                style={{ color: "#007bff" }}
              >
                {t("label.arrival")}
              </h2>
              &nbsp; &nbsp; &nbsp;
              <h2
                className="inline cursor-pointer underline"
                style={{ color: "#007bff" }}
                onClick={() => { navigate("/lcp") }}
              >
                {t("label.loadComplete")}
              </h2>
            </label>
          </div>
        </div>

        {compound && compound.subCompounds
         ? 
        <div className="md:col-6 col-12">
          <Fieldset className="flex justify-content-center align-items-end border-round-md" style={{ boxShadow: "0px 0px 1px #6c757d", height: "92%" }}>

            <div className="flex justify-content-center">
              <span className="p-float-label w-full md:w-7">
                <Dropdown
                  inputId="subCompound"
                  value={selectedSubCompound}
                  onChange={(e) => {
                    onSubCompoundDropDownChange(e);
                  }}
                  options={subCompoundList}
                  optionLabel="label"
                  className="w-full"
                  disabled={subCompoundList.length === 0}
                />
                <label htmlFor="subCompound">{t("label.subCompound")}</label>
              </span>
            </div>

            <PrimeChart type="bar"
              data={finalArrivalChartLine}
              options={finalArrivalChartOptionsLine}
              className="responsive-graph"
            />

          </Fieldset>
          <div className="flex justify-content-center">
            <h2
              className="inline cursor-pointer underline"
              style={{ color: "#007bff" }}
              onClick={() => { navigate("/finalArrival") }}
            >
              {t("label.finalArrival")}
            </h2>
          </div>
        </div>
        : null
        }
      </div>
    </>
  );
};