import { Menu } from "primereact/menu";
import { Sidebar } from "primereact/sidebar";
import { useEffect } from "react";
import { isUserMasterUser } from "../../util/Utility";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export const SideMenu = (props) => {

    useEffect(() => {
            isUserMasterUser()
            &&
            pages.push({
                label: t("TopNav.master"),
                command: () => {
                    props.gotToPage("/master");
                },
                disabled: shouldMenuItemBeDisabled("/master"),
            });
            
            if (props.compound && props.compound.subCompounds) {
                pages.push( {
                    label: t("TopNav.finalArrival"),
                    command: () => {
                        props.gotToPage("/finalArrival");
                    },
                    disabled: shouldMenuItemBeDisabled("/finalArrival"),
                },);
                   
            }
    })

    const { t } = useTranslation();

    const location = useLocation();

    const shouldMenuItemBeDisabled = (currentPageAddress) => {
        if (currentPageAddress === location.pathname) {
            return true;
        }
        return false;
    };

    const pages = [
        {
            label: t("TopNav.dashboard"),
            command: () => {
                props.gotToPage("/");
            },
            disabled: shouldMenuItemBeDisabled("/"),
        },
        {
            label: t("TopNav.arrival"),
            command: () => {
                props.gotToPage("/arrival");
            },
            disabled: shouldMenuItemBeDisabled("/arrival"),
        },
        {
            label: t("TopNav.lcp"),
            command: () => {
                props.gotToPage("/lcp");
            },
            disabled: shouldMenuItemBeDisabled("/lcp"),
        },
    ];

    return (
        <>
            <Sidebar visible={props.sideMenuVisible} onHide={() => props.setSideMenuVisible(false)}>
                <div className="flex flex-column align-items-center gap-3">
                    <Menu model={pages} className="w-full border-none" style={{ fontSize: "1rem" }} />
                </div>
            </Sidebar>
        </>
    )
}