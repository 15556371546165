import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_POST_UPDATE_ARRIVAL } from '../../actions/ApiConstants';
import { convertToYYYYMMDDWithoutSlash, convertToHHmmss } from '../../util/Utility';
import { Toast } from 'primereact/toast';
import { postCallWithoutReducer } from '../../actions/performAction';
import { BANNER_LIFE_TIME } from '../../util/Constants';

export const UpdateArrivalDialog = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [arrivalTime, setArrivalTime] = useState(new Date());

    const onClickConfirm = () => {

        let updateArrivalDto = {
            arrivalDate: convertToYYYYMMDDWithoutSlash(arrivalDate),
            arrivalTime: convertToHHmmss(arrivalTime),
            transportIds: Array.from(props.selectedArrivalData.map((data) => data.transportId))
        }

        dispatch(
            postCallWithoutReducer(
                API_POST_UPDATE_ARRIVAL,
                updateArrivalDto,
                props.onSuccessSaveUpdateArrival,
                onFailure
            )
        );
    }
    const onFailure = (error) => {
        toast.current.show({ severity: "error", summary: "Error", detail: error, life: BANNER_LIFE_TIME });
    };

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            {t("label.arrivalConfirmation")}
        </div>
    );

    const footerContent = (
        <div className='flex justify-content-end'>
            <div>
                <Button label={t("button.confirm")}
                    icon="pi pi-check"
                    size='small'
                    onClick={onClickConfirm}
                />
            </div>
            <div>
                <Button label={t("button.cancel")} icon="pi pi-times" onClick={() => props.setUpdateArrivalDialogVisible(false)} size='small' />
            </div>
        </div>);

    return (
        <>
            <Toast ref={toast} position="top-center" />

            <Dialog visible={props.updateArrivalDialogVisible} modal header={headerElement} footer={footerContent} onHide={() => props.setUpdateArrivalDialogVisible(false)} closable
                breakpoints={{ '1000px': "60%", '800px': "70%", '667px': '25rem' }}
            >
                <div className='grid justify-content-center gap-4 m-2 pt-1'>
                    <div className="col-12 md:w-7">
                        <span className="p-float-label">
                            <Calendar inputId="arrivalDate"
                                value={arrivalDate} onChange={(e) => setArrivalDate(e.value)}
                                dateFormat='dd/mm/yy'
                                showIcon />
                            <label htmlFor="arrivalDate">{t("label.arrivalDate")}</label>
                        </span>
                    </div>
                    <div className="col-12 md:w-7">
                        <span className="p-float-label">
                            <Calendar inputId="time"
                                value={arrivalTime}
                                onChange={(e) => setArrivalTime(e.value)}
                                showIcon
                                timeOnly
                                dateFormat='hh:mm:ss'
                                icon={() => <i className='pi pi-clock' />} />
                            <label htmlFor="time">{t("label.arrivalTime")}</label>
                        </span>
                    </div>
                </div>
            </Dialog>
        </>
    );
};