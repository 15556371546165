import { Fieldset } from "primereact/fieldset";
import { Calendar } from "primereact/calendar";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UnauthenticatedComponent } from "../atoms/UnauthenticatedComponent";
import { useTranslation } from "react-i18next";
import { UpdateArrivalDialog } from "../organisms/UpdateArrivalDialog";
import {
  addSequenceNumberToEachItemInCollection,
  convertToDashYYYYMMDD,
  dateComparision,
  getPaginationRows,
  selectAllCheckboxVisible,
  sortDateInDataTable,
  userHaveWriteAccessOnSelectedCompound,
} from "../../util/Utility";
import { Toast } from "primereact/toast";
import {
  downloadFile,
  getCallWithoutReducer,
} from "../../actions/performAction";
import { useDispatch } from "react-redux";
import {
  API_DOWNLOAD_ARRIVAL_EXCEL,
  API_GET_ARRIVAL_DETAILS,
} from "../../actions/ApiConstants";
import { Tooltip } from "primereact/tooltip";
import {
  APPLICATION_FUNCTION_ARRIVAL,
  ARRIVED,
  BANNER_LIFE_TIME,
  CALENDAR_FORMAT,
  DIALOG_BOX_ARRIVAL,
  IN_BOUND,
  PENDING,
  RAIL,
  TRUCK,
} from "../../util/Constants";
import { UploadExcelDialog } from "../organisms/UploadExcelDialog";
import { VinDetailsDialogForArrival } from "../organisms/VinDetailsDialogForArrival";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "./Arrival.css";
import { isAutheticated } from "../../util/AuthService";

export const Arrival = () => {
  const [searchCriteria, setSearchCriteria] = useState({
    etaFromDate: "",
    etaToDate: "",
    transportMethod: "",
    status: IN_BOUND,
  });

  useEffect(() => {
    setWriteAccess(userHaveWriteAccessOnSelectedCompound());
    dispatch(
      getCallWithoutReducer(
        API_GET_ARRIVAL_DETAILS,
        searchCriteria,
        addSequenceToArrivalResult,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const toast = useRef(null);
  const [etaFromDate, setEtaFromDate] = useState();
  const [etaToDate, setEtaToDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState(IN_BOUND);
  const [transportMethod, setTransportMethod] = useState("BOTH");
  const [updateArrivalDialogVisible, setUpdateArrivalDialogVisible] =
    useState(false);
  const [selectedArrivalData, setSelectedArrivalData] = useState([]);
  const [arrivalResult, setArrivalResult] = useState();
  const [writeAccess, setWriteAccess] = useState(false);
  const [importExportDialogVisible, setImportExportDialogVisible] =
    useState(false);
  const [
    vinDetailsDialogForArrivalVisible,
    setVinDetailsDialogForArrivalVisible,
  ] = useState(false);
  const [selectedTransportId, setSelectedTransportId] = useState(null);
  const dispatch = useDispatch();

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    transportId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lp: { value: null, matchMode: FilterMatchMode.CONTAINS },
    location: { value: null, matchMode: FilterMatchMode.CONTAINS },
    arrivalStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const [errorInEtaToDate, setErrorInEtaToDate] = useState({
    error: false,
    message: "",
  });

  const statusList = [
    { label: "ARRIVED", value: "ARRIVED" },
    { label: "INBOUND", value: "INBOUND" },
    { label: "ALL", value: "ALL" },
  ];

  const tranportTypeList = [
    { label: "TRUCK", value: TRUCK },
    { label: "RAIL", value: RAIL },
    { label: "BOTH", value: "BOTH" },
  ];

  const addSequenceToArrivalResult = (arrivalResult) => {
    setArrivalResult(addSequenceNumberToEachItemInCollection(arrivalResult));
  };

  const getBackgroundForStatusCol = (rowData) => {
    if (rowData) {
      if (rowData.status === ARRIVED) {
        return <div className="arrivedBackground">{rowData.status}</div>;
      } else if (rowData.status === IN_BOUND) {
        return <div className="inboundBackground">{rowData.status}</div>;
      } else if (rowData.status === PENDING) {
        return <div className="pendingBackground">{rowData.status}</div>;
      }
    }
  };

  const setHyperLinkforTransportId = (rowData) => {
    return (
      <span
        id="transport-id-hyperLink"
        className="underline cursor-pointer"
        onClick={() => {
          setVinDetailsDialogForArrivalVisible(true);
          setSelectedTransportId(rowData);
        }}
      >
        {rowData.transportId}
      </span>
    );
  };

  const showToastWhenNoArrivalDataSelected = () => {
    toast.current.show({
      severity: "warn",
      summary: "No Row Selected",
      detail: "Please select atleast one row.",
      life: BANNER_LIFE_TIME,
    });
  };

  const onClickUpdateArrivalButton = () => {
    if (selectedArrivalData.length === 0) {
      showToastWhenNoArrivalDataSelected();
      return;
    }
    setUpdateArrivalDialogVisible(true);
  };

  const onClickSearch = () => {
    var flag = true;
    //var transportIdArr = [];
    //var vinArr = [];

    if ((etaToDate && !etaFromDate) || (!etaToDate && etaFromDate)) {
      toast.current.show({
        severity: "warn",
        summary: "Only one ETA date selected",
        detail: "Please select both ETA from and ETA to Date",
        life: BANNER_LIFE_TIME,
      });
      flag = false;
    }

    if (etaToDate && etaFromDate) {
      if (dateComparision(etaToDate, etaFromDate)) {
        setErrorInEtaToDate({
          error: true,
          message: "ETA To date must be greater then ETA From date.",
        });
        flag = false;
      }
    }

    // if (transportIds) {
    //   transportIdArr = transportIds.split(",");
    //   transportIdArr.forEach(id => {

    //     if (!isValidAlphaNumeric(id) || id.trim.length != 11) {
    //       setErrorInTransportId(true);
    //       flag = false;
    //     }
    //   });
    // }

    // if (VINs) {
    //   vinArr = VINs.split(",");
    //   vinArr.forEach(vin => {
    //     if (!isValidAlphaNumeric(vin) || vin.trim.length != 17) {
    //       setErrorInVin(true);
    //       flag = false;
    //     }
    //   }); }

    if (flag) {
      let searchCriteria = {
        etaFromDate: etaFromDate ? convertToDashYYYYMMDD(etaFromDate) : "",
        etaToDate: etaToDate ? convertToDashYYYYMMDD(etaToDate) : "",
        transportMethod: transportMethod ? transportMethod : "BOTH",
        status: selectedStatus ? selectedStatus : IN_BOUND,
      };

      setSearchCriteria(searchCriteria);

      dispatch(
        getCallWithoutReducer(
          API_GET_ARRIVAL_DETAILS,
          searchCriteria,
          addSequenceToArrivalResult,
          onFailure
        )
      );
    }
  };

  const onFailure = (error) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error,
      life: BANNER_LIFE_TIME,
    });
  };

  const OnClickReset = () => {
    setEtaFromDate();
    setEtaToDate();
    //setTransportIds("");
    //setVINs("");
    setSelectedStatus(IN_BOUND);
    setTransportMethod("BOTH")
    setErrorInEtaToDate({ error: false, message: "" });
  };

  const setMinDate = () => {
    var date = new Date();
    date.setDate(date.getDate() - 365);
    return date;
  };

  const setMaxDate = () => {
    var date = new Date();
    date.setDate(date.getDate() + 365);
    return date;
  };

  const selectArrivalData = (e) => {
    if (e.type === "all" && selectedArrivalData.length === 0) {
      let onlyArrivedData = [];
      e.value.map((obj) =>
        obj.status === IN_BOUND ? onlyArrivedData.push(obj) : ""
      );
      setSelectedArrivalData(onlyArrivedData);
      return;
    }

    if (e.type === "all" && selectedArrivalData.length > 0) {
      setSelectedArrivalData([]);
      return;
    }

    if (e.type === "checkbox") {
      let onlyArrivedData = [];

      if (selectedArrivalData.length === 0 && e.value[0].status === IN_BOUND) {
        setSelectedArrivalData(e.value);
        return;
      }

      if (selectedArrivalData.length > 0) {
        e.value.map((obj) =>
          obj.status === IN_BOUND ? onlyArrivedData.push(obj) : ""
        );
        setSelectedArrivalData(onlyArrivedData);
        return;
      }
    }
  };

  const onClickExportToExcel = () => {
    dispatch(
      downloadFile(
        API_DOWNLOAD_ARRIVAL_EXCEL,
        searchCriteria,
        APPLICATION_FUNCTION_ARRIVAL,
        null,
        onFailure
      )
    );
  };

  const setArrivalResultTableHeader = () => {
    return (
      <div>
        <div className="inline">{t("label.result")}</div>

        <div
          className="flex gap-2"
          style={{ float: "right", marginTop: "-8px" }}
        >
          <div
            className="inline cursor-pointer export-to-excel-icon"
            data-pr-tooltip="Download table data as Excel"
            data-pr-position="left"
          >
            <Button
              icon="pi pi-file-excel"
              size="small"
              className="excel-download-button"
              onClick={onClickExportToExcel}
              disabled={arrivalResult && arrivalResult?.length === 0}
            />
            <Tooltip target=".export-to-excel-icon" />
          </div>

          <div className="">
            <span
              className="cursor-pointer upload-icon"
              data-pr-tooltip="Upload data as excel"
              data-pr-position="left"
            >
              <Button
                icon="pi pi-upload"
                size="small"
                className="excel-upload-button"
                onClick={() =>
                  setImportExportDialogVisible(!importExportDialogVisible)
                }
              />
            </span>
            <Tooltip target=".upload-icon" />
          </div>
          <div className="inline">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const refreshArrivalData = () => {
    dispatch(
      getCallWithoutReducer(
        API_GET_ARRIVAL_DETAILS,
        searchCriteria,
        addSequenceToArrivalResult,
        onFailure
      )
    );
  };

  const onSuccessSaveUpdateArrival = () => {
    toast.current.show({
      severity: "success",
      summary: "Updated",
      detail: "Arrival Information Updated.",
      life: BANNER_LIFE_TIME,
    });
    setUpdateArrivalDialogVisible(false);
    setSelectedArrivalData([]);
    refreshArrivalData();
  };

  return (
    <>
      {/* <UnauthenticatedTemplate>
        <UnauthenticatedComponent />
      </UnauthenticatedTemplate> */}

      {!isAutheticated() && <UnauthenticatedComponent />}

      {/* <AuthenticatedTemplate> */}

      {isAutheticated() && (
        <>
          <Toast ref={toast} position="top-center" />

          <Fieldset legend={t("label.arrivalSearch")}>
            <div className="grid">
              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Calendar
                    inputId="etaFromDate"
                    value={etaFromDate}
                    dateFormat={CALENDAR_FORMAT}
                    onChange={(e) => {
                      setEtaFromDate(e.value);
                      setErrorInEtaToDate({ error: false, message: "" });
                    }}
                    minDate={setMinDate()}
                    maxDate={setMaxDate()}
                    showIcon
                  />
                  <label htmlFor="etaFromDate">{t("label.etaFromDate")}</label>
                </span>
              </div>

              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Calendar
                    inputId="etaToDate"
                    value={etaToDate}
                    dateFormat={CALENDAR_FORMAT}
                    onChange={(e) => {
                      setEtaToDate(e.value);
                      setErrorInEtaToDate({ error: false, message: "" });
                    }}
                    minDate={setMinDate()}
                    maxDate={setMaxDate()}
                    showIcon
                  />
                  <label htmlFor="etaToDate">{t("label.etaToDate")}</label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{
                    visibility: `${errorInEtaToDate.error ? "" : "hidden"}`,
                  }}
                >
                  {errorInEtaToDate.message}
                </span>
              </div>

              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Dropdown
                    inputId="transportMethod"
                    value={transportMethod}
                    onChange={(e) => setTransportMethod(e.value)}
                    options={tranportTypeList}
                    optionLabel="label"
                    className="w-full"
                    showClear
                  />
                  <label htmlFor="transportMethod">{t("label.transportMethod")}</label>
                </span>
              </div>

              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Dropdown
                    inputId="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.value)}
                    options={statusList}
                    optionLabel="label"
                    className="w-full"
                    showClear
                  />
                  <label htmlFor="status">{t("label.arrivalStatus")}</label>
                </span>
              </div>

              <div className="flex md:col-4 col-12 justify-content-end mt-3">
                <div className="mr-3">
                  <Button
                    label={t("button.search")}
                    icon="pi pi-search"
                    severity="primary"
                    onClick={onClickSearch}
                  />
                </div>
                <div className="mr-3">
                  <Button
                    label={t("button.reset")}
                    icon="pi pi-refresh"
                    severity="primary"
                    onClick={OnClickReset}
                    outlined
                  />
                </div>
              </div>
            </div>
          </Fieldset>
          <br></br>

          <div className="flex flex-column overflow-auto">
            <div className="arrivalTableClass">
              <DataTable
                value={arrivalResult}
                scrollable
                header={setArrivalResultTableHeader}
                stripedRows
                selectionMode="checkbox"
                selection={selectedArrivalData}
                onSelectionChange={(e) => selectArrivalData(e)}
                dataKey="sequenceNumber"
                metaKeySelection={false}
                paginator
                rows={20}
                rowsPerPageOptions={getPaginationRows()}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                dragSelection
                removableSort
                showGridlines
                filters={filters}
                globalFilterFields={[
                  "transportId",
                  "lp",
                  "location",
                  "arrivalStatus",
                  "status",
                ]}
                emptyMessage="No data found"
                showSelectionElement={(e) => {
                  if (e.status === ARRIVED || e.status === PENDING) {
                    return "";
                  } else return true;
                }}
                showSelectAll={selectAllCheckboxVisible(arrivalResult)}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>

                <Column
                  field="transportId"
                  header={t("label.transportId")}
                  filter
                  filterPlaceholder="Search by transportId"
                  sortable
                  body={(rowData) => setHyperLinkforTransportId(rowData)}
                />

                <Column 
                  field="lp" 
                  header={t("label.lp")} 
                  filter
                  filterPlaceholder="Search by LP" 
                  sortable />

                <Column
                  field="location"
                  filter
                  filterPlaceholder="Search by Location"
                  header={t("label.location")}
                  sortable
                />

                <Column
                  field="eta"
                  header={t("label.eta")}
                  filter
                  filterPlaceholder="Search by ETA"
                  sortable
                  sortFunction={(e) => sortDateInDataTable(e, "eta")}
                />
                <Column
                  field="status"
                  filter
                  filterPlaceholder="Search by Status"
                  header={t("label.arrivalStatus")}
                  sortable
                  body={getBackgroundForStatusCol}
                ></Column>
              </DataTable>
            </div>

            {writeAccess && (
              <div className="flex justify-content-end mt-3">
                <div className="mr-3">
                  <Button
                    label={t("button.updateArrival")}
                    severity="primary"
                    icon="pi pi-pencil"
                    onClick={() => onClickUpdateArrivalButton()}
                    disabled={arrivalResult && arrivalResult?.length === 0}
                  />
                </div>
              </div>
            )}
            <br />
          </div>

          {updateArrivalDialogVisible && (
            <UpdateArrivalDialog
              updateArrivalDialogVisible={updateArrivalDialogVisible}
              setUpdateArrivalDialogVisible={setUpdateArrivalDialogVisible}
              selectedArrivalData={selectedArrivalData}
              onSuccessSaveUpdateArrival={onSuccessSaveUpdateArrival}
            />
          )}

          <UploadExcelDialog
            dialogBoxFor={DIALOG_BOX_ARRIVAL}
            importExportDialogVisible={importExportDialogVisible}
            setImportExportDialogVisible={setImportExportDialogVisible}
            refreshData={refreshArrivalData}
          />

          {vinDetailsDialogForArrivalVisible && (
            <VinDetailsDialogForArrival
              selectedTransportId={selectedTransportId}
              vinDetailsDialogForArrivalVisible={
                vinDetailsDialogForArrivalVisible
              }
              setVinDetailsDialogForArrivalVisible={
                setVinDetailsDialogForArrivalVisible
              }
              getBackgroundForStatusCol={getBackgroundForStatusCol}
            />
          )}
        </>
      )}
      {/* </AuthenticatedTemplate> */}
    </>
  );
};
