import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Fieldset } from "primereact/fieldset";
import { Calendar } from "primereact/calendar";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UnauthenticatedComponent } from "../atoms/UnauthenticatedComponent";
import { useTranslation } from "react-i18next";
import { UpdateFinalArrivalDialog } from "../organisms/UpdateFinalArrivalDialog";
import {
  addSequenceNumberToEachItemInCollection,
  convertToDashYYYYMMDD,
  dateComparision,
  getPaginationRows,
  selectAllCheckboxVisible,
  sortDateInDataTable,
  userHaveWriteAccessOnSelectedCompound,
} from "../../util/Utility";
import {
  downloadFile,
  getCallWithoutReducer,
} from "../../actions/performAction";
import { useDispatch } from "react-redux";
import {
  API_DOWNLOAD_FINAL_ARRIVAL_EXCEL,
  API_GET_FINAL_ARRIVAL_DETAILS,
  API_GET_SUB_COMPOUNDS_OF_MAIN_COMPOUND,
} from "../../actions/ApiConstants";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import {
  APPLICATION_FUNCTION_FINAL_ARRIVAL,
  ARRIVED,
  BANNER_LIFE_TIME,
  CALENDAR_FORMAT,
  DIALOG_BOX_FINAL_ARRIVAL,
  IN_BOUND,
  PENDING,
} from "../../util/Constants";
import { UploadExcelDialog } from "../organisms/UploadExcelDialog";
import { FilterMatchMode } from "primereact/api";
import "./FinalArrival.css";
import { Toast } from "primereact/toast";
import { VinDetailsDialogForArrival } from "../organisms/VinDetailsDialogForArrival";
import { isAutheticated } from "../../util/AuthService";

export const FinalArrival = () => {
  useEffect(() => {
    setWriteAccess(userHaveWriteAccessOnSelectedCompound());
    dispatch(
      getCallWithoutReducer(
        API_GET_SUB_COMPOUNDS_OF_MAIN_COMPOUND,
        "",
        setSubCompoundList,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const toast = useRef(null);
  const [etaFromDate, setEtaFromDate] = useState();
  const [etaToDate, setEtaToDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState(IN_BOUND);
  const [updateFinalArrivalDialogVisible, setUpdateFinalArrivalDialogVisible] =
    useState(false);
  const [selectedFinalArrivalData, setSelectedFinalArrivalData] = useState([]);
  const [finalArrivalResult, setFinalArrivalResult] = useState([]);
  const [writeAccess, setWriteAccess] = useState(false);
  const [importExportDialogVisible, setImportExportDialogVisible] =
    useState(false);
  const [
    vinDetailsDialogForFinalArrivalVisible,
    setVinDetailsDialogForFinalArrivalVisible,
  ] = useState(false);
  const [selectedTransportId, setSelectedTransportId] = useState(null);
  const [subCompoundList, setSubCompoundList] = useState([]);
  const [selectedSubCompound, setSelectedSubCompound] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState();

  const dispatch = useDispatch();

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    transportId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lp: { value: null, matchMode: FilterMatchMode.CONTAINS },
    location: { value: null, matchMode: FilterMatchMode.CONTAINS },
    arrivalStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const [errorInEtaToDate, setErrorInEtaToDate] = useState({
    error: false,
    message: "",
  });

  const [errorInSelectedSubCompound, setErrorInSelectedSubCompound] = useState({
    error: false,
    message: "",
  });

  const statusList = [
    { label: "ARRIVED", value: "ARRIVED" },
    { label: "INBOUND", value: "INBOUND" },
    { label: "ALL", value: "ALL" },
  ];

  const getBackgroundForStatusCol = (rowData) => {
    if (rowData) {
      if (rowData.status === ARRIVED) {
        return <div className="arrivedBackground">{rowData.status}</div>;
      } else if (rowData.status === IN_BOUND) {
        return <div className="inboundBackground">{rowData.status}</div>;
      } else if (rowData.status === PENDING) {
        return <div className="pendingBackground">{rowData.status}</div>;
      }
    }
  };

  const addSequenceToFinalArrivalResult = (arrivalResult) => {
    setFinalArrivalResult(
      addSequenceNumberToEachItemInCollection(arrivalResult)
    );
  };

  const setHyperLinkforTransportId = (rowData) => {
    return (
      <span
        id="transport-id-hyperLink"
        className="underline cursor-pointer"
        onClick={() => {
          setVinDetailsDialogForFinalArrivalVisible(true);
          setSelectedTransportId(rowData);
        }}
      >
        {rowData.transportId}
      </span>
    );
  };

  const showToastWhenNoFinalArrivalDataSelected = () => {
    toast.current.show({
      severity: "warn",
      summary: "No Row Selected",
      detail: "Please select atleast one row.",
      life: BANNER_LIFE_TIME,
    });
  };

  const onClickUpdateFinalArrivalButton = () => {
    if (selectedFinalArrivalData.length === 0) {
      showToastWhenNoFinalArrivalDataSelected();
      return;
    }
    setUpdateFinalArrivalDialogVisible(true);
  };

  const onClickSearch = () => {
    var flag = true;
    // var transportIdArr = [];
    // var vinArr = [];

    if ((etaToDate && !etaFromDate) || (!etaToDate && etaFromDate)) {
      toast.current.show({
        severity: "warn",
        summary: "Only one ETA date selected",
        detail: "Please select both ETA from and ETA to Date",
        life: BANNER_LIFE_TIME,
      });
      flag = false;
    }

    if (etaToDate && etaFromDate) {
      if (dateComparision(etaToDate, etaFromDate)) {
        setErrorInEtaToDate({
          error: true,
          message: "ETA To date must be greater then ETA From date.",
        });
        flag = false;
      }
    }

    if (!selectedSubCompound) {
      setErrorInSelectedSubCompound({
        error: true,
        message: "Please select Sub-Compound.",
      });
      flag = false;
    }

    // if (transportIds) {
    //   transportIdArr = transportIds.split(",");
    //   transportIdArr.forEach(id => {

    //  if (!isValidAlphaNumeric(id) || id.trim.length != 11) {
    //       setErrorInTransportId(true);
    //       flag = false;
    //     }
    //   });
    // }

    // if (VINs) {
    //   vinArr = VINs.split(",");
    //   vinArr.forEach(vin => {
    //     if (!isValidAlphaNumeric(vin) || vin.trim.length != 17) {
    //       setErrorInVin(true);
    //       flag = false;
    //     }
    //   });
    // }
    if (flag) {
      let searchCriteria = {
        etaFromDate: etaFromDate ? convertToDashYYYYMMDD(etaFromDate) : "",
        etaToDate: etaToDate ? convertToDashYYYYMMDD(etaToDate) : "",
        status: selectedStatus ? selectedStatus : IN_BOUND,
        subCompoundCode: selectedSubCompound,
      };

      setSearchCriteria(searchCriteria);

      dispatch(
        getCallWithoutReducer(
          API_GET_FINAL_ARRIVAL_DETAILS,
          searchCriteria,
          addSequenceToFinalArrivalResult,
          onFailure
        )
      );
    }
  };

  const onFailure = (error) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error,
      life: BANNER_LIFE_TIME,
    });
  };

  const OnClickReset = () => {
    setEtaFromDate();
    setEtaToDate();
    setSelectedStatus(null);
    setSelectedSubCompound(null);
    setErrorInEtaToDate({ error: false, message: "" });
    setErrorInSelectedSubCompound({ error: false, message: "" });
  };

  const setMinDate = () => {
    var date = new Date();
    date.setDate(date.getDate() - 365);
    return date;
  };

  const setMaxDate = () => {
    var date = new Date();
    date.setDate(date.getDate() + 91);
    return date;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="">
        <DataTable
          value={data.vinFinalArrivalDtos}
          dataKey={"vins"}
          header={t("label.vinsDetails")}
        >
          <Column field="vins" header={t("label.vins")} sortable></Column>
          <Column field="lp" header={t("label.lp")} sortable></Column>
          <Column
            field="location"
            header={t("label.location")}
            sortable
          ></Column>
          <Column field="eta" header={t("label.eta")} sortable></Column>
          <Column
            field="status"
            header={t("label.status")}
            body={getBackgroundForStatusCol}
            sortable
          ></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.vinFinalArrivalDtos.length > 0;
  };

  const selectFinalArrivalData = (e) => {
    if (e.type === "all" && selectedFinalArrivalData.length === 0) {
      let onlyArrivedData = [];
      e.value.map((obj) =>
        obj.status === IN_BOUND ? onlyArrivedData.push(obj) : ""
      );
      setSelectedFinalArrivalData(onlyArrivedData);
      return;
    }

    if (e.type === "all" && selectedFinalArrivalData.length > 0) {
      setSelectedFinalArrivalData([]);
      return;
    }

    if (e.type === "checkbox") {
      let onlyArrivedData = [];

      if (
        selectedFinalArrivalData.length === 0 &&
        e.value[0].status === IN_BOUND
      ) {
        setSelectedFinalArrivalData(e.value);
        return;
      }

      if (selectedFinalArrivalData.length > 0) {
        e.value.map((obj) =>
          obj.status === IN_BOUND ? onlyArrivedData.push(obj) : ""
        );
        setSelectedFinalArrivalData(onlyArrivedData);
        return;
      }
    }
  };

  const onClickExportToExcel = () => {
    dispatch(
      downloadFile(
        API_DOWNLOAD_FINAL_ARRIVAL_EXCEL,
        searchCriteria,
        APPLICATION_FUNCTION_FINAL_ARRIVAL,
        null,
        onFailure
      )
    );
  };

  const setFinalArrivalResultTableHeader = () => {
    return (
      <div>
        <div className="inline">{t("label.finalArrResult")} </div>

        <div
          className="flex gap-2"
          style={{ float: "right", marginTop: "-8px" }}
        >
          <div
            className="inline cursor-pointer export-to-excel-icon"
            data-pr-tooltip="Download table data as Excel"
            data-pr-position="left"
          >
            <Button
              icon="pi pi-file-excel"
              size="small"
              className="excel-download-button"
              onClick={onClickExportToExcel}
              disabled={finalArrivalResult && finalArrivalResult?.length === 0}
            />
            <Tooltip target=".export-to-excel-icon" />
          </div>

          <div className="">
            <span
              className="cursor-pointer upload-icon"
              data-pr-tooltip="Upload data as excel"
              data-pr-position="left"
            >
              <Button
                icon="pi pi-upload"
                size="small"
                className="excel-upload-button"
                onClick={() =>
                  setImportExportDialogVisible(!importExportDialogVisible)
                }
              />
            </span>
            <Tooltip target=".upload-icon" />
          </div>
          <div className="inline">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const refreshFinalArrivalData = () => {
    dispatch(
      getCallWithoutReducer(
        API_GET_FINAL_ARRIVAL_DETAILS,
        searchCriteria,
        addSequenceToFinalArrivalResult,
        onFailure
      )
    );
  };

  const onSuccessSaveUpdateFinalArrival = () => {
    toast.current.show({
      severity: "success",
      summary: "Updated",
      detail: "Final Arrival Information Updated.",
      life: BANNER_LIFE_TIME,
    });
    setUpdateFinalArrivalDialogVisible(false);
    setSelectedFinalArrivalData([]);
    refreshFinalArrivalData();
  };

  return (
    <>
      {/* <UnauthenticatedTemplate> */}
      {!isAutheticated() && <UnauthenticatedComponent />}
      {/* </UnauthenticatedTemplate> */}

      {/* <AuthenticatedTemplate> */}
      {isAutheticated() && (
        <>
          <Toast ref={toast} position="top-center" />

          <Fieldset legend={t("label.finalArrSearch")}>
            <div className="grid">
              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Calendar
                    inputId="etaFromDate"
                    value={etaFromDate}
                    dateFormat={CALENDAR_FORMAT}
                    onChange={(e) => {
                      setEtaFromDate(e.value);
                      setErrorInEtaToDate({ error: false, message: "" });
                    }}
                    minDate={setMinDate()}
                    maxDate={setMaxDate()}
                    showIcon
                  />
                  <label htmlFor="etaFromDate">{t("label.etaFromDate")}</label>
                </span>
              </div>

              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Calendar
                    inputId="etaToDate"
                    dateFormat={CALENDAR_FORMAT}
                    value={etaToDate}
                    onChange={(e) => {
                      setEtaToDate(e.value);
                      setErrorInEtaToDate({ error: false, message: "" });
                    }}
                    minDate={setMinDate()}
                    maxDate={setMaxDate()}
                    showIcon
                  />
                  <label htmlFor="etaToDate">{t("label.etaToDate")}</label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{
                    visibility: `${errorInEtaToDate.error ? "" : "hidden"}`,
                  }}
                >
                  {errorInEtaToDate.message}
                </span>
              </div>

              {/* <div className="md:col-4 col-12 mt-3">
                <span className="p-float-label">
                  <InputTextarea id="transportIds"
                    value={transportIds}
                    onChange={(e) => { setTransportIds(e.target.value); setErrorInTransportId(false) }}
                    autoResize
                    rows={1}
                    cols={1}
                    className={`md:w-7 w-full  ${errorInTransportId ? "p-invalid" : ""}`}
                  />
                  <label htmlFor="transportIds">
                    {t("label.tarnsportIds")}
                  </label>
                </span>
                <span className="p-error block pt-1" style={{ visibility: `${errorInTransportId ? "" : "hidden"}` }}>
                  Invalid Transport Ids
                </span>
              </div>
  
              <div className="md:col-4 col-12 mt-3">
                <span className="p-float-label ">
                  <InputTextarea id="VINS"
                    value={VINs}
                    onChange={(e) => { setVINs(e.target.value); setErrorInVin(false) }}
                    autoResize
                    rows={1}
                    cols={1}
                    className={`md:w-7 w-full ${errorInVin ? "p-invalid" : ""}`} />
                  <label htmlFor="vins">{t("label.vins")}</label>
                </span>
                <span className="p-error block pt-1" style={{ visibility: `${errorInVin ? "" : "hidden"}` }}>
                  Invalid VINs
                </span>
              </div> */}

              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Dropdown
                    inputId="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.value)}
                    options={statusList}
                    optionLabel="label"
                    className="w-full"
                    showClear
                  />
                  <label htmlFor="status">{t("label.arrivalStatus")}</label>
                </span>
              </div>

              <div className="md:col-2 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Dropdown
                    inputId="subCompound"
                    value={selectedSubCompound}
                    onChange={(e) => {
                      setSelectedSubCompound(e.value);
                      setErrorInSelectedSubCompound({
                        error: false,
                        message: "",
                      });
                    }}
                    options={subCompoundList}
                    optionLabel="label"
                    className="w-full"
                    showClear
                  />
                  <label htmlFor="subCompound">{t("label.subCompound")}</label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{
                    visibility: `${
                      errorInSelectedSubCompound.error ? "" : "hidden"
                    }`,
                  }}
                >
                  {errorInSelectedSubCompound.message}
                </span>
              </div>

              <div className="flex md:col-4 col-12 justify-content-end mt-3">
                <div className="mr-3">
                  <Button
                    label={t("button.search")}
                    icon="pi pi-search"
                    severity="primary"
                    onClick={onClickSearch}
                  />
                </div>
                <div className="mr-3">
                  <Button
                    label={t("button.reset")}
                    icon="pi pi-refresh"
                    severity="primary"
                    onClick={OnClickReset}
                    outlined
                  />
                </div>
              </div>
            </div>
          </Fieldset>
          <br></br>

          <div className="flex flex-column overflow-auto">
            <div className="finalArrivalTableClass">
              <DataTable
                value={finalArrivalResult}
                scrollable
                scrollHeight="400px"
                header={setFinalArrivalResultTableHeader}
                stripedRows
                tableStyle={{ minWidth: "50rem" }}
                selectionMode="checkbox"
                selection={selectedFinalArrivalData}
                onSelectionChange={(e) => selectFinalArrivalData(e)}
                dataKey="sequenceNumber"
                metaKeySelection={false}
                paginator
                rows={20}
                rowsPerPageOptions={getPaginationRows()}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                dragSelection
                removableSort
                showGridlines
                filters={filters}
                globalFilterFields={[
                  "transportId",
                  "lp",
                  "location",
                  "arrivalStatus",
                  "status",
                ]}
                emptyMessage="No data found"
                showSelectionElement={(e) => {
                  if (e.status === ARRIVED || e.status === PENDING) {
                    return "";
                  } else return true;
                }}
                showSelectAll={selectAllCheckboxVisible(finalArrivalResult)}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>

                <Column
                  field="transportId"
                  header={t("label.transportId")}
                  filter
                  filterPlaceholder="Search by transportId"
                  sortable
                  body={(rowData) => setHyperLinkforTransportId(rowData)}
                />

                <Column
                  field="lp"
                  header={t("label.lp")}
                  filter
                  filterPlaceholder="Search by LP"
                  sortable
                />

                <Column
                  field="location"
                  filter
                  filterPlaceholder="Search by Location"
                  header={t("label.location")}
                  sortable
                />

                <Column
                  field="eta"
                  header={t("label.eta")}
                  filter
                  filterPlaceholder="Search by ETA"
                  sortable
                  sortFunction={(e) => sortDateInDataTable(e, "eta")}
                />
                <Column
                  field="status"
                  filter
                  filterPlaceholder="Search by Status"
                  header={t("label.arrivalStatus")}
                  sortable
                  body={getBackgroundForStatusCol}
                ></Column>
              </DataTable>
            </div>

            {writeAccess && (
              <div className="flex justify-content-end mt-3">
                <div className="mr-3">
                  <Button
                    label={t("button.updateFinalArr")}
                    severity="primary"
                    icon="pi pi-pencil"
                    onClick={() => onClickUpdateFinalArrivalButton()}
                    disabled={
                      finalArrivalResult && finalArrivalResult?.length === 0
                    }
                  />
                </div>
              </div>
            )}
            <br />
          </div>

          {updateFinalArrivalDialogVisible && (
            <UpdateFinalArrivalDialog
              updateFinalArrivalDialogVisible={updateFinalArrivalDialogVisible}
              setUpdateFinalArrivalDialogVisible={
                setUpdateFinalArrivalDialogVisible
              }
              selectedFinalArrivalData={selectedFinalArrivalData}
              onSuccessSaveUpdateFinalArrival={onSuccessSaveUpdateFinalArrival}
              selectedSubCompound={selectedSubCompound}
            />
          )}

          <UploadExcelDialog
            dialogBoxFor={DIALOG_BOX_FINAL_ARRIVAL}
            importExportDialogVisible={importExportDialogVisible}
            setImportExportDialogVisible={setImportExportDialogVisible}
            refreshData={refreshFinalArrivalData}
          />

          {vinDetailsDialogForFinalArrivalVisible && (
            <VinDetailsDialogForArrival
              selectedTransportId={selectedTransportId}
              vinDetailsDialogForArrivalVisible={
                vinDetailsDialogForFinalArrivalVisible
              }
              setVinDetailsDialogForArrivalVisible={
                setVinDetailsDialogForFinalArrivalVisible
              }
              getBackgroundForStatusCol={getBackgroundForStatusCol}
            />
          )}
        </>
      )}
      {/* </AuthenticatedTemplate> */}
    </>
  );
};
