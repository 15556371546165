export const CONTENT_TYPE = "application/json";
export const ACCEPT_TYPE = "application/json";
export const ACCEPT_TYPE_DOWNLOAD = "application/octet-stream";
export const CONTENT_TYPE_UPLOAD = "multipart/form-data";
export const DIALOG_BOX_FOR_ADD = "Add";
export const DIALOG_BOX_EDIT = "Edit";
export const IN_BOUND = "INBOUND";
export const ARRIVED = "ARRIVED";
export const PENDING = "PENDING";
export const LOAD_COMPLETE = "LOADCOMPLETE";
export const IN_STOCK = "INSTOCK";
export const ACTIVE = "Active";
export const IN_ACTIVE = "In-Active";
export const ROLE_LOGISTIC_PARTNER_SUPPORT = "LOGISTIC_PARTNER_SUPPORT";
export const ROLE_LOGISTIC_PARTNER = "LOGISTIC_PARTNER";
export const COMPOUND_TYPE_MAIN = "MAIN";
export const COMPOUND_TYPE_SUB_COMPOUND = "SUB";
export const ERROR_NETWORK = "ERR_NETWORK";
export const DIALOG_BOX_ARRIVAL = "Arrival";
export const DIALOG_BOX_LOAD_COMPLETE = "Load Complete";
export const DIALOG_BOX_FINAL_ARRIVAL = "Final Arrival";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const CALENDAR_FORMAT = "dd/mm/yy";
export const BANNER_LIFE_TIME = "5000";
export const TRUCK = "TRUCK";
export const RAIL = "RAIL";

// ---------Application Function------
export const APPLICATION_FUNCTION_ARRIVAL = "ARRIVAL";
export const APPLICATION_FUNCTION_LCP = "LOAD COMPLETE";
export const APPLICATION_FUNCTION_LCP_TEMPLATE_DOWNLOAD = "LOAD COMPLETE_TEMPLATE_DOWNLOAD";
export const APPLICATION_FUNCTION_FINAL_ARRIVAL = "FINAL ARRIVAL";


// ----------FileRequestStatus-----------
export const FILE_STATUS_REQUESTED = "REQUESTED";
export const FILE_STATUS_PROCESSING = "PROCESSING";
export const FILE_STATUS_ERROR = "ERROR";
export const FILE_STATUS_NO_DATA = "NODATA";
export const FILE_STATUS_FAILED = "FAILED";
export const FILE_STATUS_UPLOADED_SUCCESSFULLY = "UPLSY";
export const FILE_STATUS_DATA_HAS_ERROR = "DHER";
export const FILE_STATUS_READY_FOR_DOWNLOAD = "DLDCMPLTED";
export const NO_DATA_TO_UPLOAD_ERROR_CODE = "NDTU";
export const NO_SUB_COMPOUND_FOUND_ERROR_CODE = "NSCF";
export const ONLY_SKIPPED_RECORDS_ERROR_CODE = "OSRE"
export const SUCCESS_WITH_SKIPPED_RECORDS_ERROR_CODE = "SWSR";


//-----------Excel template files----------
export const ARRIVAL_TEMPLATE = "/excelTemplates/ArrivalTemplate.xlsx";
export const FINAL_ARRIVAL_TEMPLATE = "/excelTemplates/FinalArrivalTemplate.xlsx";
export const LOAD_COMPLETE_TEMPLATE = "/excelTemplates/LoadCompleteTemplate.xlsx";

//------------APP Route URLs----------------
export const ROUTE_HOME = "/";
export const ROUTE_ARRIVAL = "/arrival";
export const ROUTE_LCP = "/lcp";
export const ROUTE_FINAL_ARRIVAL = "/finalArrival";
export const ROUTE_MASTER = "/master";
export const ROUTE_ERROR_PAGE = "/errorPage";
export const ROUTE_ARRIVAL_ANNOUNCEMENT = "/arrivalAnnouncement";

//------------Login Methods----------------
export const LOGIN_METH_AZURE = "AzureAD";
export const LOGIN_METH_TARS = "Tars";