import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUpload } from "primereact/fileupload";
import {
  APPLICATION_FUNCTION_ARRIVAL,
  APPLICATION_FUNCTION_LCP_TEMPLATE_DOWNLOAD,
  ARRIVAL_TEMPLATE,
  BANNER_LIFE_TIME,
  DIALOG_BOX_ARRIVAL,
  FILE_STATUS_UPLOADED_SUCCESSFULLY,
  FINAL_ARRIVAL_TEMPLATE,
  NO_DATA_TO_UPLOAD_ERROR_CODE,
  NO_SUB_COMPOUND_FOUND_ERROR_CODE,
  ONLY_SKIPPED_RECORDS_ERROR_CODE,
  SUCCESS_WITH_SKIPPED_RECORDS_ERROR_CODE,
} from "../../util/Constants";
import {
  APPLICATION_FUNCTION_LCP,
  DIALOG_BOX_LOAD_COMPLETE,
  APPLICATION_FUNCTION_FINAL_ARRIVAL,
  DIALOG_BOX_FINAL_ARRIVAL,
} from "../../util/Constants";
import { useDispatch } from "react-redux";
import { downloadFile, uploadFile } from "../../actions/performAction";
import {
  API_DOWNLOAD_LCP_EXCEL,
  API_UPLOAD_ARRIVAL_EXCEL,
} from "../../actions/ApiConstants";
import {
  API_UPLOAD_LCP_EXCEL,
  API_UPLOAD_FINAL_ARRIVAL_EXCEL,
} from "../../actions/ApiConstants";
import { Toast } from "primereact/toast";

export const UploadExcelDialog = (props) => {
  const { t } = useTranslation();
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [dialogBoxFor] = useState(props.dialogBoxFor);
  const fileUploadRef = useRef(null);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      Upload Excel For {dialogBoxFor}
    </div>
  );

  // const onClickRefresh = () => {
  //     getUploadDetails();
  // }

  const footerContent = (
    <div className="grid align-items-center">
      <div className="col-12 md:col lg:col align-self-end">
        {/* <Button
                    label={t("button.refresh")}
                    icon="pi pi-refresh"
                    onClick={onClickRefresh}
                    size="small"
                    tooltip={"Manually refresh status of file"}
                /> */}

        <Button
          label={t("button.cancel")}
          icon="pi pi-times"
          onClick={() => props.setImportExportDialogVisible(false)}
          size="small"
        />
      </div>
    </div>
  );

  const isValidFile = (files) => {
    if (files.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Multiple Files Uploaded",
        detail: "Please upload only one file.",
        life: BANNER_LIFE_TIME,
      });
      return false;
    }
    if (files[0] !== null && files[0] !== "" && files[0] !== undefined) {
      const validFileFormat = ["xlsx"];

      var extension = files[0].name.split(".").pop();
      if (validFileFormat.indexOf(extension) >= 0) {
        return true;
      }
      toast.current.show({
        severity: "error",
        summary: "File Type",
        detail: "Upload .xlsx file only.",
        life: BANNER_LIFE_TIME,
      });
      return false;
    }
    // else {
    //     toast.current.show({
    //         severity: "error",
    //         summary: "Select a file",
    //         detail: "No file selected.",
    // life: BANNER_LIFE_TIME,
    //       });
    //     return false;
    // }
  };

  const onUpload = (event) => {
    const file = event.files[0];
    event.options.clear();

    if (isValidFile(event.files)) {
      const postData = new FormData();
      postData.append("file", file);

      if (dialogBoxFor === DIALOG_BOX_ARRIVAL) {
        dispatch(
          uploadFile(
            API_UPLOAD_ARRIVAL_EXCEL,
            postData,
            APPLICATION_FUNCTION_ARRIVAL,
            onSuccessUpload,
            onFailure
          )
        );
      } else if (dialogBoxFor === DIALOG_BOX_LOAD_COMPLETE) {
        dispatch(
          uploadFile(
            API_UPLOAD_LCP_EXCEL,
            postData,
            APPLICATION_FUNCTION_LCP,
            onSuccessUpload,
            onFailure
          )
        );
      } else if (dialogBoxFor === DIALOG_BOX_FINAL_ARRIVAL) {
        dispatch(
          uploadFile(
            API_UPLOAD_FINAL_ARRIVAL_EXCEL,
            postData,
            APPLICATION_FUNCTION_FINAL_ARRIVAL,
            onSuccessUpload,
            onFailure
          )
        );
      }
    }
  };

  const onSuccessUpload = (successMsg) => {
    if (successMsg === FILE_STATUS_UPLOADED_SUCCESSFULLY) {
      toast.current.show({
        severity: "success",
        summary: "Successfully Uploaded",
        detail: "File succesfully uploaded.",
        life: BANNER_LIFE_TIME,
      });
      props.refreshData();
      return;
    } else if (successMsg === NO_DATA_TO_UPLOAD_ERROR_CODE) {
      toast.current.show({
        severity: "warn",
        summary: "Empty File Uploaded",
        detail: "Empty file is uploaded.",
        life: BANNER_LIFE_TIME,
      });
      return;
    } else if (successMsg === NO_SUB_COMPOUND_FOUND_ERROR_CODE) {
      toast.current.show({
        severity: "error",
        summary: "No Sub Compound",
        detail: "No active sub compound found for selected main compound.",
        life: BANNER_LIFE_TIME,
      });
      return;
    } else if (successMsg === SUCCESS_WITH_SKIPPED_RECORDS_ERROR_CODE) {
      toast.current.show({
        severity: "info",
        summary: "Success with Skipped Records",
        detail: "File with skipped records will be downloaded shortly.",
        life: BANNER_LIFE_TIME,
      });
      props.refreshData();
      return;
    } else if (successMsg === ONLY_SKIPPED_RECORDS_ERROR_CODE) {
      toast.current.show({
        severity: "warn",
        summary: "Only Skipped Records",
        detail: "File with skipped records will be downloaded shortly.",
        life: BANNER_LIFE_TIME,
      });
      return;
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error in file data",
        detail:
          "Errors found in uploaded file data, Error file will be downloaded shortly.",
        life: BANNER_LIFE_TIME,
      });
    }
  };

  const onClickDownloadTemplate = () => {
    var filePath = null;

    if (dialogBoxFor === DIALOG_BOX_ARRIVAL) {
      filePath = ARRIVAL_TEMPLATE;
    } else if (dialogBoxFor === DIALOG_BOX_LOAD_COMPLETE) {
      // filePath = LOAD_COMPLETE_TEMPLATE;
      dispatch(
        downloadFile(
          API_DOWNLOAD_LCP_EXCEL,
          props.searchCriteria,
          APPLICATION_FUNCTION_LCP_TEMPLATE_DOWNLOAD,
          null,
          onFailure
        )
      );
    } else if (dialogBoxFor === DIALOG_BOX_FINAL_ARRIVAL) {
      filePath = FINAL_ARRIVAL_TEMPLATE;
    }

    if (filePath) {
      window.open(filePath, "Download");
    }
  };

  const onFailure = (error) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error,
      life: BANNER_LIFE_TIME,
    });
  };

  const setDetailForDownloadFieldSet = (dialogBoxFor) => {
    if (dialogBoxFor === DIALOG_BOX_LOAD_COMPLETE) {
      return "Download main table data to perform Load Complete";
    } else {
      return "Use this Excel template to perform " + dialogBoxFor;
    }
  };
  // const getUploadDetails = () => {
  //     if (dialogBoxFor === DIALOG_BOX_ARRIVAL) {
  //         dispatch(getCall(API_GET_FILE_REQUEST_STATUS, null, APPLICATION_FUNCTION_ARRIVAL, REDUCER_UPLOAD_FILE_STATUS, null, null));
  //     }
  //     else if (dialogBoxFor === DIALOG_BOX_LOAD_COMPLETE) {
  //         dispatch(getCall(API_GET_FILE_REQUEST_STATUS, null, APPLICATION_FUNCTION_LCP, REDUCER_UPLOAD_FILE_STATUS, null, null));
  //     }
  //     else if (dialogBoxFor === DIALOG_BOX_FINAL_ARRIVAL) {
  //         dispatch(getCall(API_GET_FILE_REQUEST_STATUS, null, APPLICATION_FUNCTION_FINAL_ARRIVAL,
  //             REDUCER_UPLOAD_FILE_STATUS, null, null));
  //     }
  // }

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Dialog
        visible={props.importExportDialogVisible}
        modal
        header={headerElement}
        footer={footerContent}
        onHide={() => props.setImportExportDialogVisible(false)}
        closable
        style={{ maxWidth: "600px", height: "505px" }}
        breakpoints={{
          "2000px": "40rem",
          "1000px": "50rem",
          "800px": "40rem",
          "667px": "30rem",
        }}
      >
        <Fieldset legend="Download">
          <div className="flex align-items-center">
            <div>
              <Button
                label={
                  dialogBoxFor === DIALOG_BOX_LOAD_COMPLETE
                    ? t("button.exportToExcel")
                    : t("button.downloadTemplate")
                }
                icon="pi pi-download"
                onClick={onClickDownloadTemplate}
                //Disable this button when dialog box is for LCP and no data is present in main table to export
                disabled={
                  dialogBoxFor === DIALOG_BOX_LOAD_COMPLETE &&
                  props.shouldExportToExcelDisabled
                }
              />
            </div>
            <div className="ml-4">
              <span>{setDetailForDownloadFieldSet(dialogBoxFor)}</span>
            </div>
          </div>
        </Fieldset>

        <Fieldset legend="Upload" className="mt-3">
          <div className="flex align-items-center">
            <div className="w-full">
              <FileUpload
                // mode="basic"
                ref={fileUploadRef}
                name="uploadExcel"
                accept=".xlsx"
                // auto
                chooseLabel="Choose Excel"
                customUpload
                // onClear={clearFiles}
                uploadHandler={onUpload}
                // onUpload={onUpload}
                cancelOptions
              />

            </div>
          </div>
        </Fieldset>
      </Dialog>
    </>
  );
};
